<template>
  <asterix-section>
    <template #content>
      <div class="flex justify-end items-end md:items-center w-full p-2 mb-2 bg-white rounded">
        <div class="flex flex-wrap min-w-max">
          <div class="m-1">
            <sun-search-input />
          </div>
        </div>
      </div>
      <sun-data-table class="w-full" :content="items" :headers="headers" :loading="isLoading">
        <template #[`col.name`]="{ item }">
          <sun-data-table-cell> [ {{ item.walletType.name }} ] {{ item.user.name }}</sun-data-table-cell>
        </template>
        <template #[`col.actions`]="{ item }">
          <sun-data-table-cell>
            <table-action-menu :actions="actions" :item="item" :items="items" @click="onActionClick($event, item)" />
          </sun-data-table-cell>
        </template>

        <template #empty> <asterix-no-data class="bg-white" /> </template>
      </sun-data-table>
      <sun-pagination-page
        :key="currentPage"
        :total-pages="totalPages"
        :total-items="totalItems"
        :current-page="currentPage"
        :value="itemsPerPage"
        class="my-6"
        @changePage="goToPage({ page: $event })"
        @changePerPage="changeItemsPerPage"
      />
    </template>
  </asterix-section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { indexMixin } from '@/mixins/index/indexMixin';
import AsterixSection from '@/components/templates/AsterixSection';
import AsterixNoData from '@/components/organisms/shared/AsterixNoData';
import Column from '@/model/shared/Column';
import apiRequest from '@/utils/apiRequest';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { getWalletByClient } from '@/services/modules/socialAudience/wallet';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { CONTEXT_NS, ACTIVE_CONTEXT_KEY } from '@/store/modules/context/keys';
import { transactionsList } from '@/router/private/modules/socialAudience/admin/wallet/transactionsList';

export default {
  name: 'ClientWalletList',
  components: {
    AsterixSection,
    AsterixNoData,
    TableActionMenu: () => import('@/components/organisms/shared/TableActionMenu'),
  },
  filters: {},
  mixins: [indexMixin],
  data: () => ({
    headers: [
      new Column('NAME', 'name').asSort().setClass('max-w-lg truncate font-bold'),
      new Column('BALANCE', 'balance'),
      new Column('ACTIONS', 'actions').setClass('w-4'),
    ],
    items: [],
    actions: [{ name: 'Show Wallet' }],
  }),
  computed: {
    ...mapState(CONTEXT_NS, {
      activeContext: ACTIVE_CONTEXT_KEY,
    }),
  },
  async mounted() {
    await this.getTableItems();
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    async getTableItems() {
      this.isLoading = true;

      await apiRequest(async () => {
        const params = new QueryParamsBuilder(this.currentPage, this.itemsPerPage);

        const { data, meta } = await getWalletByClient(this.activeClient.id, params);
        this.items = data;
        this.totalPages = meta?.totalPages || 1;
        this.totalItems = meta?.totalResults || data?.length;
      }).catch(error => {
        this.createToast(Toast.error('Comment not get transitions', error.message));
      });

      this.isLoading = false;
    },
    onActionClick(_, wallet) {
      this.$router.push({ name: transactionsList.name, params: { walletId: wallet.id } });
    },
  },
};
</script>

<style scoped></style>
