var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-section", {
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass:
                  "flex justify-end items-end md:items-center w-full p-2 mb-2 bg-white rounded",
              },
              [
                _c("div", { staticClass: "flex flex-wrap min-w-max" }, [
                  _c(
                    "div",
                    { staticClass: "m-1" },
                    [_c("sun-search-input")],
                    1
                  ),
                ]),
              ]
            ),
            _c("sun-data-table", {
              staticClass: "w-full",
              attrs: {
                content: _vm.items,
                headers: _vm.headers,
                loading: _vm.isLoading,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: `col.name`,
                    fn: function ({ item }) {
                      return [
                        _c("sun-data-table-cell", [
                          _vm._v(
                            " [ " +
                              _vm._s(item.walletType.name) +
                              " ] " +
                              _vm._s(item.user.name)
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: `col.actions`,
                    fn: function ({ item }) {
                      return [
                        _c(
                          "sun-data-table-cell",
                          [
                            _c("table-action-menu", {
                              attrs: {
                                actions: _vm.actions,
                                item: item,
                                items: _vm.items,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onActionClick($event, item)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "empty",
                    fn: function () {
                      return [
                        _c("asterix-no-data", { staticClass: "bg-white" }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            }),
            _c("sun-pagination-page", {
              key: _vm.currentPage,
              staticClass: "my-6",
              attrs: {
                "total-pages": _vm.totalPages,
                "total-items": _vm.totalItems,
                "current-page": _vm.currentPage,
                value: _vm.itemsPerPage,
              },
              on: {
                changePage: function ($event) {
                  return _vm.goToPage({ page: $event })
                },
                changePerPage: _vm.changeItemsPerPage,
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }